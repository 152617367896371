import { createApp } from "vue";
import { upperFirst, camelCase } from "lodash";

const app = createApp({});
const requireComponent = require.context("@/components/", true, /.vue$/);

requireComponent.keys().forEach((fileName) => {
    const componentConfig = requireComponent(fileName);
    const componentName = upperFirst(
        camelCase(
            fileName
                .split("/")
                .pop()
                .replace(/\.\w+$/, "")
        )
    );

    app.component(componentName, componentConfig.default || componentConfig);
});

app.mount("#app");
